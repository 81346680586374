var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.serviceRequest
        ? _c(
            "b-card-actions",
            {
              attrs: {
                title: _vm.$t("ServiceRequest.DetailsCardTitle", {
                  id: _vm.serviceRequest.id,
                }),
                "action-refresh": "",
                "show-loading": _vm.fetching,
              },
              on: { refresh: _vm.getData },
            },
            [
              _c(
                "FormulateForm",
                { ref: "form" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "4" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              value: _vm.serviceRequest.serviceArea.name,
                              type: "label",
                              label: _vm.$t("Area"),
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "4" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              value: _vm.serviceRequest.serviceCategory.name,
                              type: "label",
                              label: _vm.$t("Categoria"),
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "4" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              value: _vm.serviceRequest.createdBy,
                              type: "label",
                              label: _vm.$t("Solicitante"),
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "4" } },
                        [
                          _vm.serviceRequest.serviceCategory.requestedFor ==
                          "Person"
                            ? _c("FormulateInput", {
                                attrs: {
                                  value: _vm.serviceRequest.user.name,
                                  type: "label",
                                  label: _vm.$t("Beneficiário"),
                                },
                              })
                            : _c("FormulateInput", {
                                attrs: {
                                  value: _vm.serviceRequest.store.name,
                                  type: "label",
                                  label: _vm.$t("Beneficiário"),
                                },
                              }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "4" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              value: _vm.serviceRequest.status,
                              type: "label",
                              label: _vm.$t("Status"),
                              "is-badge": "",
                              options: _vm.serviceRequestStatusOptions,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "4" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              value: _vm.serviceRequest.createdDate,
                              type: "label",
                              label: _vm.$t("Criado em"),
                              filter: "datetime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "8" } },
                        [
                          _c("FormulateInput", {
                            staticStyle: { "font-size": "1.5rem" },
                            attrs: {
                              type: "textarea",
                              rows: "20",
                              readonly: _vm.readOnly,
                              label: _vm.$t("Descrição"),
                            },
                            model: {
                              value: _vm.serviceRequest.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.serviceRequest, "description", $$v)
                              },
                              expression: "serviceRequest.description",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "4" } },
                        [
                          _c(
                            "span",
                            { staticClass: "mb-1" },
                            [
                              _vm._v(" " + _vm._s(_vm.$t("Anexos")) + " "),
                              _c(
                                "b-badge",
                                {
                                  attrs: { pill: "", variant: "light-primary" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.serviceRequest.documents.length
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._l(
                            _vm.serviceRequest.documents,
                            function (document) {
                              return _c(
                                "p",
                                { key: document.id, staticClass: "mt-1" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "mr-50" },
                                    [
                                      !_vm.readOnly
                                        ? _c("e-button", {
                                            staticClass: "px-0",
                                            attrs: {
                                              title: _vm.$t("Deletar anexo"),
                                              icon: "trash",
                                              variant: "danger",
                                              size: "sm",
                                            },
                                            on: {
                                              click: function () {
                                                return _vm.onRemoveDocument(
                                                  document.id
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "ml-50",
                                      attrs: {
                                        href: document.url,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c("b-img", {
                                        staticClass: "mr-1",
                                        attrs: {
                                          src: _vm.getIconDocument(
                                            document.url
                                          ),
                                          height: "auto",
                                          width: "20",
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(_vm._s(document.name)),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }
                          ),
                          !_vm.readOnly
                            ? _c(
                                "p",
                                [
                                  _c("files-form", {
                                    attrs: {
                                      files: _vm.files,
                                      "is-read-only": _vm.readOnly,
                                    },
                                    on: {
                                      "set-files": _vm.setFiles,
                                      "remove-file": _vm.removeFile,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.approvalFlows, function (approvalFlow) {
        return _c(
          "b-card-actions",
          {
            key: approvalFlow.id,
            attrs: { "action-refresh": "", "show-loading": _vm.fetching },
            on: { refresh: _vm.getData },
          },
          [
            _c(
              "template",
              { slot: "title" },
              [
                _c("FormulateInput", {
                  staticClass: "card-title",
                  attrs: {
                    type: "label",
                    value:
                      _vm.$t("ApprovalFlow.DetailsCardTitle", {
                        id: approvalFlow.id,
                      }) +
                      " - " +
                      _vm.getApprovalStatus(approvalFlow.status),
                    "router-to": {
                      name: "approval-flow-details",
                      params: { id: approvalFlow.id },
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "b-row",
              [
                _c("b-col", { attrs: { md: "12" } }, [
                  _c(
                    "div",
                    [
                      _c(
                        "app-timeline",
                        _vm._l(approvalFlow.steps, function (step) {
                          return _c(
                            "app-timeline-item",
                            {
                              key: step.id,
                              attrs: {
                                variant: _vm.getStatusIcon(step.status),
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0",
                                },
                                [
                                  _c("h6", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("ApprovalFlow.StepTitle", {
                                            sequence: step.sequence,
                                          })
                                        ) +
                                        " - " +
                                        _vm._s(step.name) +
                                        " "
                                    ),
                                  ]),
                                  _c("small", { staticClass: "text-muted" }, [
                                    _vm._v(
                                      _vm._s(
                                        step.elapsedTimeFormat
                                          ? _vm.getApprovalStatus(step.status) +
                                              ": " +
                                              step.elapsedTimeFormat
                                          : ""
                                      )
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      _vm._s(
                                        step.durationTimeFormat
                                          ? "Duração: " +
                                              step.durationTimeFormat
                                          : ""
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.getApprovalStatus(step.status)) +
                                    " " +
                                    _vm._s(
                                      step.approvedBy
                                        ? "por " + step.approvedBy
                                        : ""
                                    ) +
                                    " "
                                ),
                              ]),
                              _vm._l(step.approvalGroups, function (group) {
                                return _c(
                                  "div",
                                  { key: group.id },
                                  _vm._l(group.approvals, function (approval) {
                                    return _c("span", { key: approval.id }, [
                                      approval.observation
                                        ? _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("Observação")) +
                                                " de " +
                                                _vm._s(
                                                  approval.responsibleUserName
                                                ) +
                                                ": " +
                                                _vm._s(approval.observation) +
                                                " "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ])
                                  }),
                                  0
                                )
                              }),
                              _vm._l(step.documents, function (document) {
                                return _c("p", { key: document.id }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "nav-link",
                                      attrs: {
                                        href: document.url,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c("b-img", {
                                        staticClass: "mr-1",
                                        attrs: {
                                          src: _vm.getIconDocument(
                                            document.url
                                          ),
                                          height: "auto",
                                          width: "20",
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(_vm._s(document.name)),
                                      ]),
                                    ],
                                    1
                                  ),
                                ])
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-sm-row flex-column justify-content-between align-items-start",
                                },
                                [
                                  _c("div", { staticClass: "mb-1 mb-sm-0" }, [
                                    step.approvalType == "Owner"
                                      ? _c(
                                          "span",
                                          { staticClass: "text-muted mb-50" },
                                          [_vm._v("Solicitante")]
                                        )
                                      : _vm._e(),
                                    step.approvalType == "Groups"
                                      ? _c(
                                          "span",
                                          { staticClass: "text-muted mb-50" },
                                          [_vm._v("Grupos")]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      [
                                        _vm._l(
                                          step.approvalGroups,
                                          function (group) {
                                            return _c("b-avatar", {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName: "v-b-tooltip.hover",
                                                  modifiers: { hover: true },
                                                },
                                              ],
                                              key: group.id,
                                              staticClass: "mr-50 pull-up",
                                              attrs: {
                                                text: group.approvalGroup.name.charAt(
                                                  0
                                                ),
                                                title: group.approvalGroup.name,
                                                size: "24",
                                                variant: "light-primary",
                                              },
                                            })
                                          }
                                        ),
                                        step.approvalType == "Owner"
                                          ? _c("b-avatar", {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName: "v-b-tooltip.hover",
                                                  modifiers: { hover: true },
                                                },
                                              ],
                                              staticClass: "mr-50 pull-up",
                                              attrs: {
                                                text: step.approvalFlowTransactionApproval.responsibleUserName.charAt(
                                                  0
                                                ),
                                                title:
                                                  step
                                                    .approvalFlowTransactionApproval
                                                    .responsibleUserName,
                                                size: "24",
                                                variant: "light-primary",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            2
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          2
        )
      }),
      _c("fab", {
        attrs: {
          "main-icon": "keyboard_command_key",
          actions: _vm.actions,
          "fixed-tooltip": true,
        },
        on: {
          save: function ($event) {
            return _vm.saveServiceRequest()
          },
          cancel: function ($event) {
            return _vm.cancel()
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }